export const environment: any = {
  production: false,
  name: 'development',
  local: {
    domain: 'apollo-admin-app',
    port: '4203'
  },
  localResparkeApp: {
    domain: 'apollo-app',
    port: '4201'
  },
  localFamilyApp: {
    domain: 'apollo-family-app',
    port: '4202'
  },
};
